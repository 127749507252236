/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Fullmap, FullmapWrap, Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"elaccess"} description={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Fullmap name={"q13ep9ji9wi"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"lgz2ao2y4fp"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="pb--60 pt--60" name={"ih076rdtikh"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Přístupové systémy"}>
              </Title>

              <Text className="text-box" content={"Elektronickou kontrolu pohybu osob dnes potkáváme na každém kroku. Od čipů našich dětí které chodí do školy až po přístupové karty na pracovištích nebo vstupních terminálech do bankovních domů. Elektronické přístupové systémy jsou ideálním řešením pro objekty s vysokým pohybem osob kdy zajišťují nejen kontrolu pohybu, ale zároveň šetří mechanické namáhání klasických mechanických uzamykacích systémů. Příkladem může být vstup do panelového domu který je vybaven elektronickým přístupovým systémem a nedochází tak k&nbsp; nadměrnému opotřebení cylindrické vložky.<br><br>Dle přání a požadavků klienta navrhneme řešení s případnými doplňkovými funkcionalitami. Provádíme realizace a montáže systémů od malých obsluhujících jedny dveře až po rozsáhle s desítkami dveří. Nabízíme systémy s jednoduchou správou přístupových čipů přes systémy spolupracující se systémy zvonkovými až po ty se vzdáleným online dohledem které jde spojit i s kamerovými systémy. Od systémů na jednoduché čipové přívěsky přes systémy&nbsp; využívají smartphony až po systémy s biometrickou identifikací osob.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1pn2jyn pb--80 pt--80" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/19567/b2833eca24b249ada6d4b890c5ad2cf7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Rádi vyhovíme Vašim přáním a požadavkům</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}